<template>
    <div
        :ref="'consoleWrapper'"
        class="flex flex-col relative z-10">
        <div class="h-12 border-t border-ide-gray bg-ide-gray flex flex-row justify-between items-center">
            <div class="flex flex-row justify-start pl-2">
                <button
                    v-for="component in filterComponents(consoleCompnents)"
                    :key="component.key"
                    class="transition duration-200 ease-in-out hover:text-gray-100 px-2 uppercase tracking-wide text-xs outline-none focus:outline-none"
                    :class="{
                        'text-ide-dock': consoleCompnent !== component.key,
                        'text-gray-100': consoleCompnent === component.key,
                    }"
                    @click="() => openConsole(component.key)">
                    <span class="pointer-events-none relative flex flex-row">
                        {{ component.labels[projectRuntime] || component.labels.default }}
                        <span
                            v-if="showConsoleComponentNotification(component.key)"
                            class="h-2 w-2 relative -left-1 -top-1 rounded-full bg-red-600"></span>
                    </span>
                </button>
            </div>
            <div class="flex flex-row justify-end pr-2">
                <button
                    v-if="consoleOpen && !consoleFullScreen"
                    class="console-dock-btn"
                    @click="toggleConsoleFullScreen">
                    <openFullSvg class="h-4 fill-current" />
                </button>
                <button
                    v-if="consoleOpen && consoleFullScreen"
                    class="console-dock-btn"
                    @click="toggleConsoleFullScreen">
                    <closeFullSvg class="h-4 fill-current" />
                </button>
                <button
                    v-if="consoleOpen"
                    class="console-dock-btn"
                    @click="closeConsole">
                    <closeSvg class="h-4 fill-current" />
                </button>
            </div>
        </div>

        <Logs
            v-if="consoleOpen && consoleCompnent === 'logs'"
            :logOutlet="logOutlet"
            label='logs'
            class="w-full flex-1"
        />

        <DeviceLogs
            v-if="projectRuntime === 'pio' && consoleOpen && consoleCompnent === 'board-logs' && isBoardConnected"
            :logOutlet="boardLogOutlet"
            label='board-logs'
            class="w-full flex-1"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'Console',

    props: {
        logOutlet: {
            type: Array,
            required: true,
        },

        boardLogOutlet: {
            type: Array,
            required: true,
        },

        consoleFullScreen: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            consoleCompnent: null,
            consoleCompnents: [
                { key: 'logs', labels: { pio: 'Build Logs', default: 'Logs' } },
                { key: 'board-logs', labels: { pio: 'Board Logs', default: 'Board Logs' } },
            ],
            consoleOpen: false,
            unreadLogs: false,
            unreadBoardLogs: false,
        };
    },

    computed: {
        ...mapState('project', [
            'projectRuntime',
        ]),

        ...mapState('iotDevice', [
            'isBoardConnected',
        ]),
    },

    watch: {
        logOutlet(newLogs) {
            if (newLogs.length > 0 && (!this.consoleOpen || this.consoleCompnent !== 'logs')) {
                this.unreadLogs = true;
            }
        },

        boardLogOutlet(newLogs) {
            if (newLogs.length > 0 && (!this.consoleOpen || this.consoleCompnent !== 'board-logs')) {
                this.unreadBoardLogs = true;
            }
        },
    },

    methods: {
        openConsole(component) {
            if (component) {
                if (component === 'logs') {
                    this.unreadLogs = false;
                }

                if (component === 'board-logs') {
                    this.unreadBoardLogs = false;
                }

                this.consoleCompnent = component;
            }
            this.$refs.consoleWrapper.classList.add('flex-1');
            this.consoleOpen = true;
            this.$emit('size-change');
        },

        filterComponents(components) {
            if (this.projectRuntime === 'pio' && this.isBoardConnected) {
                return components;
            }

            return components.filter((c) => c.key !== 'board-logs');
        },

        closeConsole() {
            this.$refs.consoleWrapper.classList.remove('flex-1');
            this.consoleOpen = false;
            this.consoleCompnent = null;
            this.$emit('size-change');
        },

        toggleConsoleFullScreen() {
            this.$emit('toggle-console-full-screen');
        },

        showConsoleComponentNotification(component) {
            if (component === 'logs' && this.unreadLogs) return true;
            if (component === 'board-logs' && this.unreadBoardLogs) return true;
            return false;
        },
    },

    components: {
        closeSvg: () => import('@/assets/img/close.svg'),
        openFullSvg: () => import('@/assets/img/openFull.svg'),
        closeFullSvg: () => import('@/assets/img/closeFull.svg'),
        Logs: () => import('@/components/tutorials/ide/Logs.vue'),
        DeviceLogs: () => import('@/components/tutorials/ide/DeviceLogs.vue'),
    },
};
</script>

<style lang="postcss" scoped>
.console-dock-btn {
    @apply text-ide-dock cursor-pointer transition duration-200 ease-in-out px-2 outline-none;

    :hover {
        @apply text-gray-100;
    }

    :focus {
        @apply outline-none;
    }
}
</style>
